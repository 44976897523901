import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserService } from "../services/user.service";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";
import { IUser } from "../interfaces/User";
import toast from "react-hot-toast";

const ViewUser = () => {
    const { t } = useTranslation(['home']);
    const userService = new UserService();
    const { id } = useParams();

    const [user, setUser] = useState<IUser>({
        enabled: false,
        id: undefined,
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: null,
        courseStart: "",
        receiveNewsletter: false,
        receiveLTMails: false,
        registeredPL: false,
        roles: [],
        createdAt: ""
    });

    useEffect(() => {
        loadUser(); // eslint-disable-next-line
    }, []);

    const loadUser = async () => {
        try {
            if (id) {
                const result = await userService.getById(parseInt(id));
                setUser(result);
            }
        } catch (error) {
            console.error('Error loading user:', error);
            toast.error(t('Failed to load user'));
        }
    }

    const formatDate = (dateString: string | null) => {
        if (!dateString) return '';
        return new Intl.DateTimeFormat('de-DE', {
            dateStyle: 'medium',
            timeStyle: 'short'
        }).format(new Date(dateString));
    };

    return (
        <div className="mx-auto max-w-270">
            <Breadcrumb pageName={t("View User")}/>

            <div className="grid grid-cols-5 gap-8">
                <div className="col-span-5 xl:col-span-5">
                    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                                {t("View User")}
                            </h3>
                        </div>
                        <div className="p-7">
                            <table className="w-full table-auto">
                                <tbody>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Username")}:</th>
                                    <td className="px-4 py-2">{user.username}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Firstname")}:</th>
                                    <td className="px-4 py-2">{user.firstName}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Lastname")}:</th>
                                    <td className="px-4 py-2">{user.lastName}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Email")}:</th>
                                    <td className="px-4 py-2">{user.email}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Phone")}:</th>
                                    <td className="px-4 py-2">{user.phone}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Start date")}:</th>
                                    <td className="px-4 py-2">{formatDate(user.courseStart)}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Newsletter")}:</th>
                                    <td className="px-4 py-2">{user.receiveNewsletter ? t("Yes") : t("No")}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("LT Mail")}:</th>
                                    <td className="px-4 py-2">{user.receiveLTMails ? t("Yes") : t("No")}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Registered")}:</th>
                                    <td className="px-4 py-2">{user.registeredPL ? t("Yes") : t("No")}</td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Roles")}:</th>
                                    <td className="px-4 py-2">
                                        {user.roles && user.roles.length > 0
                                            ? user.roles.join(", ")
                                            : t("No roles assigned")}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-left px-4 py-2">{t("Created")}:</th>
                                    <td className="px-4 py-2">{formatDate(user.createdAt)}</td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="flex justify-end gap-4.5 mt-6">
                                <Link to={"/users"}
                                      className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white">
                                    {t("Back")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewUser;