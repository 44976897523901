import {useEffect} from "react";
import {CategoryService} from "../../services/category.service";
import {ICategory} from "../../interfaces/Category";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {CategoryState, setCategories} from "../../features/slices/categorySlice";
import {FaEye} from "react-icons/fa";
import {setData} from "../../features/slices/userSlice";
import {User} from "../../interfaces/User";

const TableCategories = () => {
    const {t} = useTranslation(['home']);
    const {category} = useSelector((state: {category: CategoryState}) => state);

    useEffect(() => {
        fetchData(); // eslint-disable-next-line
    }, []);

    const categoryService = new CategoryService();

    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            const res: ICategory[] = await categoryService.getAll();
            dispatch(setCategories(res));
        } catch (error) {
            console.error('Error to failed load category ==>', error);
        }
    }

    return (
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">

            <div className="flex justify-start gap-4.5 mb-6.5">
                <Link to={"/category-add"} className="btn btn-primary" onClick={() => dispatch(setData(new User()))}>
                    {t("Add new category")}
                </Link>
            </div>

            <div className="max-w-full overflow-x-auto mb-6">
                <table className="w-full table-auto">
                    <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Category Id")}
                        </th>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Category Name")}
                        </th>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Description")}
                        </th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">
                            {t("Actions")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {category.list.map((item: ICategory, key) => {
                        return (
                        <tr key={key}>
                            <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                <Link to={`/category-edit`}
                                      className="text-primary dark:text-meta-3">
                                    {item.id}
                                </Link>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                <Link to={`/category-edit`}
                                      className="text-primary dark:text-meta-3">
                                    {item.name}
                                </Link>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <p className="text-black dark:text-white">
                                    {item.description}
                                </p>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <div className="flex items-center space-x-3.5">
                                    <Link to={`/category-view/${item.id}`} className="hover:text-white">
                                        <FaEye/>
                                    </Link>
                                </div>
                            </td>
                        </tr>
            )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableCategories;
