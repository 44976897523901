import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Category, ICategory} from "../../interfaces/Category";

export interface CategoryState {
	data: ICategory;
	list: ICategory[]
}

const initialState: CategoryState = {
	data: new Category(),
	list: []
} 

export const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: { 
		setCategoryData: (state, action: PayloadAction<ICategory>) => {
			state.data = action.payload
		},
		setCategories: (state, action: PayloadAction<ICategory[]>) => {
			state.list = action.payload
		},
	}
})

export const { setCategoryData, setCategories } = categorySlice.actions
export default categorySlice.reducer
