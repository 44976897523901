import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import ClickOutside from '../ClickOutside';
import UserOne from '../../images/user/user-01.png';
import { FiSettings, FiUser } from 'react-icons/fi';
import { RiContactsBook3Line } from 'react-icons/ri';
import { LuLogOut } from "react-icons/lu";
import toast from 'react-hot-toast';

const DropdownUser = () => {
  const { t } = useTranslation(['home']);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success(t('Logged out successfully'));
      navigate('/signin');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error(t('Error during logout'));
    }
  };

  return (
      <ClickOutside onClick={() => setDropdownOpen(false)} className="relative">
        <Link
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className="flex items-center gap-4"
            to="#"
        >
        <span className="hidden text-right lg:block">
          <span className="block text-sm font-medium text-black dark:text-white">
            {user?.username || 'User'} {/* Nutze den Username aus dem AuthContext */}
          </span>
          <span className="block text-xs">
            {user?.roles?.includes('ADMINISTRATOR') ? t("Administrator") : t("User")}
          </span>
        </span>

          <span className="h-12 w-12 rounded-full">
          <img src={UserOne} alt="User" />
        </span>

          <svg
              className="hidden fill-current sm:block"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
                fill=""
            />
          </svg>
        </Link>

        {/* <!-- Dropdown Start --> */}
        {dropdownOpen && (
            <div
                className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark`}
            >
              <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark">
                <li>
                  <Link
                      to="/settings"
                      className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                  >
                    <FiSettings />
                    {t("Account Settings")}
                  </Link>
                </li>
              </ul>
              <button
                  onClick={handleLogout}
                  className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
              >
                <LuLogOut />
                {t("Log Out")}
              </button>
            </div>
        )}
        {/* <!-- Dropdown End --> */}
      </ClickOutside>
  );
};

export default DropdownUser;