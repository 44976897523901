import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Booking } from "../../interfaces/Booking";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {BookingService} from "../../services/booking.service";
import toast from "react-hot-toast";
import {setBookings} from "../../features/slices/bookingSlice";

interface BookingListProps {
    eventId: number;
}

const TableEventBookings: React.FC<BookingListProps> = ({ eventId }) => {
    const { t } = useTranslation(['home']);
    const dispatch = useDispatch();
    const { bookings } = useSelector((state: RootState) => state.booking);
    const bookingService = new BookingService();

    useEffect(() => {
        fetchBookings(); // eslint-disable-next-line
    }, [eventId]);

    const fetchBookings = async () => {
        try {
            const res = await bookingService.getBookingsForEvent(eventId);
            dispatch(setBookings(res));
        } catch (error) {
            console.error('Error fetching bookings:', error);
            toast.error(t('Failed to load bookings'));
        }
    };

    const getUserName = (booking: Booking) => {
        if (booking.personId && booking.personId > 0) {
            return (
                <Link to={`/user-view/${booking.personId}`} className="text-primary">
                    {booking.firstName} {booking.lastName}
                </Link>
            );
        } else if (booking.bookingMeta) {
            const firstNameMeta = booking.bookingMeta.find(meta => meta.metaKey === "firstName");
            const lastNameMeta = booking.bookingMeta.find(meta => meta.metaKey === "lastName");

            if (firstNameMeta && lastNameMeta) {
                return `${firstNameMeta.metaValue} ${lastNameMeta.metaValue}`;
            }
        }
        return t("Unregistered User");
    };

    return (
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto mb-6">
                <h2 className="text-2xl font-semibold mb-4">{t("Event")} {eventId}</h2>
                <table className="w-full table-auto">
                    <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                        <th className="py-4 px-4 font-medium text-black dark:text-white">{t("Booking ID")}</th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">{t("Name")}</th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">{t("Booking Date")}</th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">{t("Status")}</th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">{t("Role")}</th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">{t("Meta")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(bookings) && bookings.map(booking => (
                        <tr key={booking.id}>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">{booking.id}</td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                {getUserName(booking)}
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                {new Intl.DateTimeFormat('de-DE', { dateStyle: 'medium' }).format(new Date(booking.bookingDate))}
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                {booking.bookingStatus ? t("Confirmed") : t("Pending")}
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                {booking.roleName}
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <ul>
                                    {booking.bookingMeta?.map(meta => (
                                        <li key={meta.metaKey}>{meta.metaKey}: {meta.metaValue}</li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TableEventBookings;