import React, { useState, useEffect } from 'react';
import { UserNoteService } from '../services/userNote.service';
import { IUserNote } from '../interfaces/UserNote';
import toast from 'react-hot-toast';
import {FaEdit, FaTrashAlt} from 'react-icons/fa';
import {t} from "i18next";

interface UserNotesProps {
  userId: number;
}

const UserNotes: React.FC<UserNotesProps> = ({ userId }) => {
  const [notes, setNotes] = useState<IUserNote[]>([]);
  const [newNote, setNewNote] = useState('');
  const [editingNote, setEditingNote] = useState<IUserNote | null>(null);
  const userNoteService = new UserNoteService();

  const fetchNotes = async () => {
    try {
      const response = await userNoteService.getAll(userId);
      setNotes(response);
    } catch (error) {
      console.error('Failed to fetch notes:', error);
      toast.error('Failed to load notes');
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [userId]);

  const handleEdit = (note: IUserNote) => {
    setEditingNote(note);
    setNewNote(note.content);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    try {
      if (editingNote) {
        await userNoteService.update(userId, editingNote.id, newNote);
        setEditingNote(null);
      } else {
        await userNoteService.create(userId, newNote);
      }
      setNewNote('');
      fetchNotes();
      toast.success(editingNote ? 'Note updated successfully' : 'Note added successfully');
    } catch (error) {
      console.error('Failed to save note:', error);
      toast.error('Failed to save note');
    }
  };

  const handleDelete = async (noteId: number) => {
    const confirmed = window.confirm('Are you sure you want to delete this note?');
    if (!confirmed) return;

    try {
      await userNoteService.delete(userId, noteId);
      fetchNotes();
      toast.success('Note deleted successfully');
    } catch (error) {
      console.error('Failed to delete note:', error);
      toast.error('Failed to delete note');
    }
  };

  return (
      <div>
        {/* Note Input Form */}
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-5.5">
            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
              {editingNote ? t("Edit Note") : t("Add Note")}
            </label>
            <textarea
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                rows={3}
                placeholder={t("Enter your note here...")}
            />
          </div>
          <div className="flex justify-end gap-4.5">
            {editingNote && (
                <button
                    type="button"
                    onClick={() => {
                      setEditingNote(null);
                      setNewNote('');
                    }}
                    className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                >
                  {t("Cancel")}
                </button>
            )}
            <button
                type="submit"
                disabled={!newNote.trim()}
                className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
            >
              {editingNote ? t("Update") : t("Add Note")}
            </button>
          </div>
        </form>

        {/* Notes List */}
        <div className="space-y-4">
          {notes.map((note) => (
              <div
                  key={note.id}
                  className="rounded-sm border border-stroke bg-gray p-4 dark:border-strokedark dark:bg-meta-4"
              >
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    <p className="text-black dark:text-white whitespace-pre-wrap">
                      {note.content}
                    </p>
                    <p className="mt-2 text-sm text-gray-500">
                      {new Date(note.createdAt).toLocaleString()}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <button
                        onClick={() => handleEdit(note)}
                        className="text-primary hover:text-meta-3"
                        title={t("Edit Note")}
                    >
                      <FaEdit/>
                    </button>
                    <button
                        onClick={() => handleDelete(note.id)}
                        className="text-danger hover:text-meta-1"
                        title={t("Delete Note")}
                    >
                      <FaTrashAlt/>
                    </button>
                  </div>
                </div>
              </div>
          ))}
          {notes.length === 0 && (
              <p className="text-center text-gray-500">
                {t("No notes yet")}
              </p>
          )}
        </div>
      </div>
  );
};

export default UserNotes;