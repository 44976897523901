import { api, headerAPI } from "../configs/axios";
import { IEvent } from '../interfaces/Event';

export class EventService {

    private apiURL = "v1/events";

    public async getAll() {
        try {
            const response = await api.get<IEvent[]>(`${this.apiURL}`, { validateStatus: () => { return true; } })
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async post(data:IEvent) {
        try {
            console.log('Posting event data:', data);  // Debug-Log
            const response = await api.post<IEvent>(`${this.apiURL}`, data, headerAPI)
            return await response.data
        } catch (error) {
            console.error('Error creating event:', error);
            throw error;
        }
    }

    public async getById(id:number){
        try {
            const response = await api.get<IEvent>(`${this.apiURL}/${id}`, headerAPI)
            const data: IEvent = response.data 
            return data
        } catch (error) {
            throw error;
        }
    }

    public async put(data:IEvent) {
        try {
            const response = await api.put<IEvent>(`${this.apiURL}/${data.id}`, data, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async delete(data:IEvent) {
        try {
            const response = await api.delete(`${this.apiURL}/${data.id}`, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }
}
