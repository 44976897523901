import { api, headerAPI } from "../configs/axios";
import { IProject } from '../interfaces/Project';

export class ProjectService {

    private apiURL = "v1/projects";

    public async getAll() {
        try {
            const response = await api.get<IProject[]>(`${this.apiURL}`, {validateStatus: () => { return true; }})
            return response.data
        } catch (error) {
            throw error;
        }
    }

    public async getAvailableProjects() {
        try {
            const response = await api.get<IProject[]>(`${this.apiURL}/available`, headerAPI)
            console.log("API Response für verfügbare Projekte:", response.data);
            return response.data
        } catch (error) {
            throw error;
        }
    }

    public async getAvailableProjectsForEvent(eventId:number) {
        try {
            const response = await api.get<IProject[]>(`${this.apiURL}/available/${eventId}`, headerAPI)
            return response.data
        } catch (error) {
            throw error;
        }
    }

    public async post(data:IProject) {
        try {
            console.log('Posting project data:', data);  // Debug-Log
            const response = await api.post<IProject>(`${this.apiURL}`, data, headerAPI)
            return response.data
        } catch (error) {
            console.error('Error creating project:', error);
            throw error;
        }
    }

    public async getById(id:number){
        try {
            const response = await api.get<IProject>(`${this.apiURL}/${id}`, headerAPI)
            const data: IProject = response.data 
            return data
        } catch (error) {
            throw error;
        }
    }

    public async put(data:IProject) {
        try {
            const response = await api.put<IProject>(`${this.apiURL}/${data.id}`, data, headerAPI)
            return response.data
        } catch (error) {
            throw error;
        }
    }

    public async delete(data:IProject) {
        try {
            const response = await api.delete(`${this.apiURL}/${data.id}`, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

}
