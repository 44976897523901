import {api, headerAPI} from "../configs/axios";
import {ICategory} from "../interfaces/Category";

export class CategoryService {
    private apiURL = `${process.env.REACT_APP_API_BASE_URL}/v1/categories`;

    public async getAll() {
        try {
            const response = await api.get<ICategory[]>(`${this.apiURL}`, {validateStatus: () => { return true; }})
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async post(data:ICategory) {
        try {
            const response = await api.post<ICategory>(`${this.apiURL}`, data, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async getById(id:number){
        try {
            const response = await api.get<ICategory>(`${this.apiURL}/${id}`, headerAPI)
            const data: ICategory = response.data
            return data
        } catch (error) {
            throw error;
        }
    }

    public async put(data:ICategory) {
        try {
            const response = await api.put<ICategory>(`${this.apiURL}/${data.id}`, data, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async delete(data:ICategory) {
        try {
            const response = await api.delete(`${this.apiURL}/${data.id}`, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }
}