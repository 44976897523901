import {IFormField} from "./FormField";

export interface IForm {
    id?: number | null;
    title?: string;
    description?: string;
    formFields?: IFormField[];
}

export class Form implements IForm {
    public id: number | null;
    public title?: string;
    public description?: string;
    public formFields?: IFormField[];

    constructor(){
        this.id = null;
        this.title = "";
        this.description = "";
        this.formFields = [];
    }
}
