import { api, headerAPI } from "../configs/axios";
import { ILocation } from '../interfaces/Location';
import {IEvent} from "../interfaces/Event";
import {Booking} from "../interfaces/Booking";

export class BookingService {

    private apiURL = "v1/events";

    public async getAll() {
        try {
            const response = await api.get<ILocation[]>(`${this.apiURL}`, {validateStatus: () => { return true; }})
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async post(data:ILocation) {
        try {
            const response = await api.post<ILocation>(`${this.apiURL}`, data, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async getById(id:number){
        try {
            const response = await api.get<ILocation>(`${this.apiURL}/${id}`, headerAPI)
            const data: ILocation = response.data 
            return data
        } catch (error) {
            throw error;
        }
    }

    public async put(data:ILocation) {
        try {
            const response = await api.put<ILocation>(`${this.apiURL}/${data.id}`, data, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async delete(data:ILocation) {
        try {
            const response = await api.delete(`${this.apiURL}/${data.id}`, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }

    }

    public async getEventsWithBookings() {
        try {
            const response = await api.get<IEvent[]>(`${this.apiURL}/with-bookings`, headerAPI);
            return await response.data;
        } catch (error) {
            throw error;
        }
    }

    public async getBookingsForEvent(eventId: number) {
        try {
            const response = await api.get<Booking[]>(`${this.apiURL}/${eventId}/bookings`, headerAPI);
            return await response.data;
        } catch (error) {
            throw error;
        }
    }

}
