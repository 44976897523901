import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ProjectService } from "../services/project.service";
import toast from "react-hot-toast";
import {IProject} from "../interfaces/Project";

const ViewProject = () => {
    const { t } = useTranslation(['home'])
    const projectService = new ProjectService();
    const{id} = useParams()

    const [project, setProject] = useState<IProject>({
        id: null,
        projectStatus: "",
        countryCode: "",
        language: "",
        projectType: "",
        isPublished: "",
        isPublic: "",
        projectNr: "",
        startDate: "",
        endDate: "",
        courseType: "",
        eventType: "",
        eventName: "",
        leader: "",
        createdAt: ""
    })

    useEffect(() => {
        loadProject()
    }, [])

    const loadProject = async () => {
        try {
            if (id) {
                const result = await projectService.getById(parseInt(id));
                setProject(result);
            }
        } catch (error) {
            console.error('Error loading user:', error);
            toast.error(t('Failed to load user'));
        }
    }

    return (
        <section>
            <p>
                <b>{t("Project number")}:</b> {project.projectNr}
            </p>
            <p>
                <b>{t("Project status")}:</b> {project.projectStatus}
            </p>
            <p>
                <b>{t("Project Type")}:</b> {project.projectType}
            </p>
            <p>
                <b>{t("Project Start")}:</b> {project.startDate}
            </p>
            <p>
                <b>{t("Project End")}:</b> {project.endDate}
            </p>
            <p>
                <b>{t("Language")}:</b> {project.language}
            </p>
            <p>
                <b>{t("Country code")}:</b> {project.countryCode}
            </p>
            <p>
                <b>{t("Created")}:</b> {project.createdAt}
            </p>


            <Link to={"/projects"} className="btn mt-8">
                {t("Cancel")}
            </Link>
        </section>
    )
}

export default ViewProject
