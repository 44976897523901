import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LocationService } from "../services/location.service";
import { Link } from "react-router-dom";
import {ILocation} from "../interfaces/Location";
import toast from "react-hot-toast";

const ViewLocation = () => {
    const { t } = useTranslation(['home'])
    const locationService = new LocationService();
    const { id } = useParams()

    const [location, setLocation] = useState<ILocation>({
        id: null,
        event: null,
        locationName: "",
        description: "",
        address: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        updatedAt: "",
        createdAt: ""
    })

    useEffect(() => {
        loadLocation()
    }, [])

    const loadLocation = async () => {
        try {
            if (id) {
                const result = await locationService.getById(parseInt(id));
                setLocation(result)
            }
        } catch (error) {
            console.error('Error loading user:', error);
            toast.error(t('Failed to load user'));
        }
    }

    return (
        <section>
            <p>
                <b>{t("Location Name")}:</b> {location.locationName}
            </p>
            <p>
                <b>{t("Description")}:</b> {location.description}
            </p>
            <p>
                <b>{t("Created")}:</b> {location.createdAt}
            </p>

            <Link to={"/locations"} className="btn mt-8">
                {t("Cancel")}
            </Link>
        </section>
    )
}

export default ViewLocation
