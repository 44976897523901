import {useEffect} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FormState, setFormData, setForms} from "../../features/slices/formSlice";
import {FormService} from "../../services/form.service";
import {IForm} from "../../interfaces/Form";
import {setProjectData} from "../../features/slices/projectSlice";
import {FaEye} from "react-icons/fa";

const TableForms = () => {
    const {t} = useTranslation(['home']);
    const {form} = useSelector((state: {form: FormState}) => state);

    useEffect(() => {
        fetchData(); // eslint-disable-next-line
    }, []);

    const formService = new FormService();

    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            const res: IForm[] = await formService.getAll();
            dispatch(setForms(res));
        } catch (error) {
            console.error('Error to failed load form ==>', error);
        }
    }

    return (
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">

            <div className="max-w-full overflow-x-auto mb-6">
                <table className="w-full table-auto">
                    <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Form Id")}
                        </th>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Form Title")}
                        </th>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Description")}
                        </th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">
                            {t("Actions")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {form.list.map((item: IForm, key) => {
                        return (
                        <tr key={key}>
                            <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                <Link to={'/form-edit'} className="text-primary dark:text-meta-3" onClick={() => dispatch(setFormData(item))}>
                                    {item.id}
                                </Link>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                <Link to={'/form-edit'} className="text-primary dark:text-meta-3" onClick={() => dispatch(setFormData(item))}>
                                    {item.title}
                                </Link>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <p className="text-black dark:text-white">
                                    {item.description}
                                </p>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <div className="flex items-center space-x-3.5">
                                    <Link to={`/form-view/${item.id}`} className="hover:text-white">
                                        <FaEye/>
                                    </Link>
                                </div>
                            </td>
                        </tr>
            )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableForms;
