import {api, headerAPI} from "../configs/axios";
import {IForm} from "../interfaces/Form";

export class FormService {
    private apiURL = `${process.env.REACT_APP_API_BASE_URL}/v1/forms`;

    public async getAll() {
        try {
            const response = await api.get<IForm[]>(`${this.apiURL}`, {validateStatus: () => { return true; }})
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async post(data:IForm) {
        try {
            const response = await api.post<IForm>(`${this.apiURL}`, data, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async getById(id:number){
        try {
            const response = await api.get<IForm>(`${this.apiURL}/${id}`, headerAPI)
            const data: IForm = response.data
            return data
        } catch (error) {
            throw error;
        }
    }

    public async put(data:IForm) {
        try {
            const response = await api.put<IForm>(`${this.apiURL}/${data.id}`, data, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }

    public async delete(data:IForm) {
        try {
            const response = await api.delete(`${this.apiURL}/${data.id}`, headerAPI)
            return await response.data
        } catch (error) {
            throw error;
        }
    }
}