import { useDispatch, useSelector } from "react-redux"
import { IEvent, Event } from "../../interfaces/Event"
import { EventState, setData, setEvents } from "../../features/slices/eventSlice";
import { setLocationData } from "../../features/slices/locationSlice";
import { useEffect } from "react";
import { EventService } from "../../services/event.service";
import { useTranslation } from "react-i18next";
import { toast, Toaster } from 'react-hot-toast';
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa"
import { Link } from "react-router-dom";
import {setProjectData} from "../../features/slices/projectSlice";

const TableEvents = () => {
  const { t } = useTranslation(['home']);
  const { plEvent } = useSelector((state: { plEvent: EventState }) => state);

  useEffect(() => {
    fetchData() // eslint-disable-next-line
  }, [])

  const eventService = new EventService();

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const res: IEvent[] = await eventService.getAll()
      dispatch(setEvents(res))
    } catch (error) {
      console.log('Error to failed load ==>', error)
    }
  }

  const onClickDelete = (item: IEvent) => {
    const confirmed = window.confirm('Are you sure you want to delete?');
    if (confirmed) {
      fetchDelete(item);
    }
  }

  const fetchDelete = async (item: IEvent) => {
    try {
      await eventService.delete(item);
      toast.success(t('The item has been deleted'));
      fetchData();
    } catch (error) {
      console.log('Error to failed load ==>', error);
      toast.error(t('Failed to delete the item'));
    }
  }

  return (
      <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <Toaster />
        <div className="flex justify-start gap-4.5 mb-6.5">
          <Link to={"/event-add"} className="btn btn-primary" onClick={() => dispatch(setData(new Event()))}>
            {t("Add new event")}
          </Link>
        </div>

        <div className="max-w-full overflow-x-auto mb-6">
          <table className="w-full table-auto">
            <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[80px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                {t("Project")}
              </th>
              <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                {t("Title")}
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Location")}
              </th>
              <th className="min-w-[170px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Date and Time")}
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Event Category")}
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Attendees")}
              </th>
              <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Category")}
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Status")}
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                {t("Actions")}
              </th>
            </tr>
            </thead>
            <tbody>
            {plEvent.list.map((item: IEvent, key) => {
              return (
                  <tr key={key}>
                    <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <Link to={'/project-edit'} className="text-primary dark:text-meta-3"
                            onClick={() => dispatch(setProjectData(item.project!))}>
                        {item.project?.id}
                      </Link>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <Link to={'/event-edit'} className="text-primary dark:text-meta-3"
                            onClick={() => dispatch(setData(item))}>
                        {item.eventName}
                      </Link>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <Link to={'/location-edit'} className="text-primary dark:text-meta-3"
                            onClick={() => dispatch(setLocationData(item.location!))}>
                        {item.location?.locationName}
                      </Link>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p className="text-black dark:text-white">

                        {new Intl.DateTimeFormat('de-DE', { dateStyle: 'medium' }).format(new Date(item.startDate))} - {new Intl.DateTimeFormat('de-DE').format(new Date(item.endDate))}<br/> {item.allDay ? t("All day") : item.startTime+" - "+item.endTime}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p className="text-black dark:text-white">
                        {item.category}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p className="text-black dark:text-white">
                        {item.maxAttendees}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p className="text-black dark:text-white">
                        {item.eventCategories?.map((category) => category.name).join(', ')}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p
                        className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${item.project?.projectStatus === "PUBLISHED" ? 'bg-success text-success' : item.project?.projectStatus === "DRAFT" ? 'bg-warning text-warning' : 'bg-error text-error'}}`} >
                        {item.project?.projectStatus === "PUBLISHED" ? t("Published") : item.project?.projectStatus === "DRAFT" ? t("Draft") : t("Review")}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <Link to={`/event-view/${item.id}`} className="hover:text-meta-3">
                          <FaEye/>
                        </Link>

                        <Link to={'/event-edit'} className="hover:text-meta-3" onClick={() => dispatch(setData(item))}>
                          <FaEdit/>
                        </Link>

                        <button className="hover:text-meta-3" onClick={() => onClickDelete(item)}>
                          <FaTrashAlt/>
                        </button>
                      </div>
                    </td>
                  </tr>
              )
            })}
            </tbody>
          </table>
        </div>

      </div>
  );
};

export default TableEvents;
