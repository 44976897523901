import { FormForm } from "../components/Forms/FormForm"

const EditForm = () => {

    return (
        <div className="w-full">
            <FormForm />
        </div>
    )
}

export default EditForm
