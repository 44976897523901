export interface ICategory {
    id?: number | null;
    name: string;
    description: string;
    updatedAt: string;
    createdAt: string;
}

export class Category implements ICategory {
    public id: number | null;
    public name: string;
    public description: string;
    public updatedAt: string;
    public createdAt: string;

    constructor(){
        this.id = null;
        this.name = "";
        this.description = "";
        this.updatedAt = "";
        this.createdAt = "";
    }
}