import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import Logo from '../../images/logo/logo-dark.svg';

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    username?: string;
    password?: string;
  }>({});
  const navigate = useNavigate();
  const { login } = useAuth();

  const validateForm = (): boolean => {
    const errors: { username?: string; password?: string } = {};
    let isValid = true;

    if (!username.trim()) {
      errors.username = 'Username is required';
      isValid = false;
    }

    if (!password.trim()) {
      errors.password = 'Password is required';
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    setLoading(true);

    try {
      const result = await login(username, password, rememberMe);
      if (result) { // Nur wenn der Login erfolgreich war
        toast.success('Login successful!');
        navigate('/');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.response?.status === 403 || error.response?.status === 401) {
        toast.error('Invalid username or password');
      } else if (error.response?.status === 400) {
        toast.error('Invalid credentials');
      } else {
        toast.error('An error occurred during login. Please try again.');
      }
      setPassword(''); // Optional: Passwort zurücksetzen bei Fehler
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-boxdark-2 dark:text-bodydark">
        <div className="w-full max-w-md rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="flex flex-col items-center">
            {/* Logo Section */}
            <div className="flex w-full items-center justify-center border-b border-stroke py-4 dark:border-strokedark">
              <img className="w-full p-5" src={Logo} alt="Logo" />
            </div>

            {/* Login Form Section */}
            <div className="w-full p-4 sm:p-12.5">
              <h2 className="mb-9 text-center text-2xl font-bold text-black dark:text-white">
                Sign In
              </h2>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    Username
                  </label>
                  <div className="relative">
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter your username"
                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    Password
                  </label>
                  <div className="relative">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>
                </div>

                <div className="mb-6 flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        className="h-5 w-5 rounded border-gray-300 text-primary focus:ring-primary dark:border-strokedark dark:bg-form-input"
                    />
                    <label
                        htmlFor="rememberMe"
                        className="ml-2 text-sm text-black dark:text-white"
                    >
                      Remember me
                    </label>
                  </div>
                  <Link
                      to="/forgot-password"
                      className="text-sm text-primary hover:underline dark:text-white"
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="mb-5">
                  <button
                      type="submit"
                      disabled={loading}
                      className={`w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 ${
                          loading ? 'opacity-50' : ''
                      }`}
                  >
                    {loading ? 'Signing in...' : 'Sign In'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SignIn;