import React from "react"
import {FormCategory} from "../components/Forms/FormCategory";

const AddCategory = () => {
    return (
        <div className="w-full">
            <FormCategory />
        </div>
    )
}

export default AddCategory
