import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import Breadcrumb from "../Breadcrumbs/Breadcrumb";
import {CategoryState, setCategories, setCategoryData} from "../../features/slices/categorySlice";
import {CategoryService} from "../../services/category.service";
import {Category, ICategory} from "../../interfaces/Category";

export const FormCategory = () => {

    let navigate = useNavigate();

    const { t } = useTranslation(['home']);

    const { category } = useSelector((state: { category: CategoryState }) => state);

    const [errorForm, setErrorForm] = useState({
        name: false,
        description: false
    })

    const dispatch = useDispatch();

    const categoryService = new CategoryService();

    const setFormValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setCategoryData({ ...category.data, [event.target.id]: event.target.value }))
    }

    const isValidForm = () => {

        const error = { name: false, description: false }

        if (!category.data.name) error.name = true
        if (!category.data.description) error.description = true

        setErrorForm(error)

        return error.name || error.description;
    }

    const fetchUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            const data: ICategory = await categoryService.put(category.data)
            const dataArray: ICategory[] = [...category.list]
            let index: number = dataArray.findIndex((item: ICategory) => item.id === data.id)
            dataArray.splice(index, 1, data);
            dispatch(setCategories(dataArray))

            toast.success(t('The data has been updated'))
            navigate("/categories")
        } catch (error) {
            console.log(error)
        }
    }

    const fetchCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            // valid fields
            if (isValidForm()) return null;

            const data: ICategory = await categoryService.post(category.data)
            // for clean form
            dispatch(setCategoryData(new Category()))
            // add item
            const dataArray: ICategory[] = [...category.list]
            dataArray.push(data)
            dispatch(setCategories(dataArray))

            toast.success('The data has been saved')
            navigate("/categories")
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error)
            }
        }
    }

    const inputCSS = "w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
    const labelCSS = "mb-3 block text-sm font-medium text-black dark:text-white"
    const inputError = "border-red-400"

    return (
        <div className="mx-auto max-w-270">
            <Breadcrumb pageName={t("Add Category")}/>

            <div className="grid grid-cols-5 gap-8">
                <div className="col-span-5 xl:col-span-5">
                    <div
                        className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                                {t("Add Category")}
                            </h3>
                        </div>

                        <div className="p-7">
                            <form onSubmit={(e) => category.data.id ? fetchUpdate(e) : fetchCreate(e)}>

                                <div className="mb-5.5">
                                    <label className={labelCSS}
                                           htmlFor="name">{t("Category Name")}</label>
                                    <div className="relative">
                                        <input
                                            id="name"
                                            type="text"
                                            placeholder={t("Category Name")}
                                            value={category.data.name}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.name ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.name &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className={labelCSS}
                                           htmlFor="description">{t("Description")}</label>
                                    <div className="relative">
                                        <input
                                            id="description"
                                            type="text"
                                            placeholder={t("Description")}
                                            value={category.data.description}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.description ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.description &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="flex justify-end gap-4.5">
                                    <Link to={"/categories"}
                                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                                          type="submit">
                                        {t("Cancel")}
                                    </Link>

                                    <button
                                        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                                        type="submit">
                                        {category.data.id ? t("Save") : t("Create")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}