import {IProject} from "./Project";
import {ICategory} from "./Category";
import {ILocation} from "./Location";

export interface IEvent {
    id?: number | null;
    project?: IProject | null;
    eventName: string;
    description: string;
    eventOwner?: number;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    allDay: boolean;
    eventType: string;
    eventStatus: string;
    category: string;
    maxAttendees?: number | null;
    eventCategories?: ICategory[];
    location: ILocation | null;
}

export class Event implements IEvent {
    public id: number | null;
    public project: IProject | null;
    public user: string;
    public eventName: string;
    public description: string;
    public startDate: string;
    public endDate: string;
    public startTime: string;
    public endTime: string;
    public allDay: boolean;
    public eventType: string;
    public eventStatus: string;
    public category: string;
    public maxAttendees: number | null;
    public eventCategories?: ICategory[];
    public location: ILocation | null;

    constructor(){
        this.id = null;
        this.project = null;
        this.user = "";
        this.eventName = "";
        this.description = "";
        this.startDate = "";
        this.endDate = "";
        this.startTime = "";
        this.endTime = "";
        this.allDay = false;
        this.eventType = "";
        this.eventStatus = "";
        this.category = "";
        this.maxAttendees = null;
        this.eventCategories = [];
        this.location = null;
    }
}

export enum EventType {
    TRAINING = 'TRAINING',
    CELEBRATION = 'CELEBRATION',
    WORKSHOP = 'WORKSHOP',
    WEBINAR = 'WEBINAR',
    SEMINAR = 'SEMINAR',
    COURSE = 'COURSE',
    CONFERENCE = 'CONFERENCE',
    MEETING = 'MEETING',
    OTHER = 'OTHER'
}

export enum EventStatus {
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    PLANNED = 'PLANNED'
}