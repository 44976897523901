import { useDispatch, useSelector } from "react-redux"
import { IUser, User } from "../../interfaces/User"
import { UserState, setData, setUsers } from "../../features/slices/userSlice";
import { useEffect } from "react";
import { UserService } from "../../services/user.service";
import { useTranslation } from "react-i18next";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa"
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const TableUsers = () => {
  const { t } = useTranslation(['home']);
  const { user } = useSelector((state: { user: UserState }) => state);

  useEffect(() => {
    fetchData() // eslint-disable-next-line
  }, [])

  const userService = new UserService();

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const res: IUser[] = await userService.getAll()
      dispatch(setUsers(res))
    } catch (error) {
      console.log('Error to failed load ==>', error)
    }
  }

  const onClickDelete = (item: IUser) => {
    const confirmed = window.confirm('Are you sure you want to delete?');
    if (confirmed) {
      fetchDelete(item);
    }
  }

  const fetchDelete = async (item: IUser) => {
    try {
      await userService.delete(item)

      toast.success('The item has been deleted')

      fetchData()

    } catch (error) {
      console.log('Error to failed load ==>', error)
    }
  }

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">

      <div className="flex justify-start gap-4.5 mb-6.5">
        <Link to={"/user-add"} className="btn btn-primary" onClick={() => dispatch(setData(new User()))}>
          {t("Add new user")}
        </Link>
      </div>

      <div className="max-w-full overflow-x-auto mb-6">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                {t("username")}
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Name")}
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Mail")}
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Role")}
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Registered")}
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                {t("Actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {user.list.map((item: IUser, key) => {
              return (
                <tr key={key}>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <Link to={'/user-edit'} className="text-primary dark:text-meta-3" onClick={() => dispatch(setData(item))}>
                      {item.username}
                    </Link>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                     {item.firstName} {item.lastName}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {item.email}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {item.roles.join(", ")}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p
                      className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${item.registeredPL ? 'bg-success text-success' : 'bg-error text-error'}}`} >
                      {item.registeredPL ? t("Yes") : t("No")}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <div className="flex items-center space-x-3.5">
                      <Link to={`/user-view/${item.id}`} className="hover:text-meta-3">
                        <FaEye />
                      </Link>

                      <Link to={'/user-edit'} className="hover:text-meta-3" onClick={() => dispatch(setData(item))}>
                        <FaEdit />
                      </Link>

                      <button className="hover:text-meta-3" onClick={() => onClickDelete(item)}>
                        <FaTrashAlt />
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default TableUsers;
