import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import {CategoryService} from "../services/category.service";
import {ICategory} from "../interfaces/Category";

const ViewCategory = () => {
    const { t } = useTranslation(['home'])
    const categoryService = new CategoryService();
    const { id } = useParams()

    const [category, setCategory] = useState<ICategory>({
        id: null,
        name: "",
        description: "",
        updatedAt: "",
        createdAt: ""
    })

    useEffect(() => {
        loadCategory()
    }, [])

    const loadCategory = async () => {
        try {
            if (id) {
                const result = await categoryService.getById(parseInt(id));
                setCategory(result)
            }
        } catch (error) {
            console.error('Error loading user:', error);
            toast.error(t('Failed to load user'));
        }
    }

    return (
        <section>
            <p>
                <b>{t("Category Id")}:</b> {category.id}
            </p>
            <p>
                <b>{t("Category Name")}:</b> {category.name}
            </p>
            <p>
                <b>{t("Description")}:</b> {category.description}
            </p>
            <p>
                <b>{t("Created")}:</b> {category.createdAt}
            </p>

            <Link to={"/categories"} className="btn mt-8">
                {t("Cancel")}
            </Link>
        </section>
    )
}

export default ViewCategory
