import axios from 'axios'
import { authService } from '../services/auth.service';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(
    (config) => {
        console.log('Request:', {
            method: config.method,
            url: config.baseURL + '/' + config.url,
            data: config.data,
            headers: config.headers
        });
      const token = authService.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        console.log('Response:', {
            status: response.status,
            data: response.data
        });
        return response;
    },
    async (error) => {
        console.error('API Error:', {
            status: error.response?.status,
            data: error.response?.data,
            config: error.config
        });

        if (error.response?.status === 401) {
            authService.logout();
            window.location.href = '/signin';
        }
        else if (error.response?.status === 403 &&
            error.response?.data?.message?.includes('JWT expired')) {
            authService.logout();
            window.location.href = '/signin?expired=true';
        }
        return Promise.reject(error);
    }
);

export const headerAPI = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}
