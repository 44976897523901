import React from 'react';
import { IUser, User, roles } from "../../interfaces/User";
import { useDispatch, useSelector } from "react-redux";
import { UserState, setData, setUsers } from '../../features/slices/userSlice';
import { UserService } from "../../services/user.service";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import Breadcrumb from "../Breadcrumbs/Breadcrumb";
import UserNotes from '../UserNotes';

export const FormUser = () => {
    let navigate = useNavigate();
    const { t } = useTranslation(['home']);
    const { user } = useSelector((state: { user: UserState }) => state);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [availableRoles, setAvailableRoles] = useState<{id: number; name: string}[]>([]);

    // Debug-Ausgabe der Benutzerdaten und Rollen
    useEffect(() => {
        if (user.data && user.data.id) {
            console.log("Benutzer zum Bearbeiten geladen:", user.data);
            console.log("Rollen des Benutzers:", user.data.roles);
        }
    }, [user.data]);

    const [errorForm, setErrorForm] = useState({
        firstName: false,
        lastName: false,
        username: false,
        enabled: false,
        email: false,
        phone: false,
        courseStart: false,
        password: false,
        confirmPassword: false
    });

    const dispatch = useDispatch();
    const userService = new UserService();

    // Fetch all available roles when component mounts
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                // Verwende den userService, um die Rollen zu laden
                const response = await userService.getRoles();
                console.log("Geladene Rollen:", response);
                setAvailableRoles(response);
            } catch (error) {
                console.error('Error fetching roles:', error);
                // Fallback to enum if API fails
                const rolesList = Object.values(roles).map((role, index) => ({
                    id: index + 1,
                    name: role
                }));
                console.log("Fallback Rollen:", rolesList);
                setAvailableRoles(rolesList);
            }
        };

        fetchRoles();
    }, []);

    const setFormValue = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        dispatch(setData({ ...user.data, [event.target.id]: event.target.value }));
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        // Check passwords match if confirm password has a value
        if (confirmPassword) {
            setPasswordsMatch(confirmPassword === event.target.value);
        }
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
        setPasswordsMatch(password === event.target.value);
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const roleId = parseInt(event.target.value);
        const selectedRole = availableRoles.find(role => role.id === roleId);

        if (selectedRole) {
            // Wir verwenden das normale Rollen-Objekt direkt
            const updatedRoles = [...user.data.roles, selectedRole];
            dispatch(setData({ ...user.data, roles: updatedRoles }));

            // Zurücksetzen des Dropdown-Werts
            event.target.value = "";
        }
    };

    const removeRole = (index: number) => {
        const updatedRoles = [...user.data.roles];
        updatedRoles.splice(index, 1);
        dispatch(setData({ ...user.data, roles: updatedRoles }));
    };

    const isValidForm = () => {
        const error = {
            firstName: false,
            lastName: false,
            username: false,
            enabled: false,
            email: false,
            phone: false,
            courseStart: false,
            password: false,
            confirmPassword: false
        };

        if (!user.data.firstName) error.firstName = true;
        if (!user.data.lastName) error.lastName = true;
        if (!user.data.username) error.username = true;
        // Das enabled-Feld ist optional und sollte nicht validiert werden
        // if (!user.data.enabled) error.enabled = true;
        if (!user.data.email) error.email = true;
        if (!user.data.phone) error.phone = true;
        if (!user.data.courseStart) error.courseStart = true;

        // Password validation for new users
        if (!user.data.id && !password) error.password = true;
        if (!user.data.id && !confirmPassword) error.confirmPassword = true;

        // Password validation when changing password
        if (password && !confirmPassword) error.confirmPassword = true;
        if (password && !passwordsMatch) error.confirmPassword = true;

        setErrorForm(error);

        return error.firstName || error.lastName || error.username ||
            error.email || error.phone ||
            error.courseStart || error.password || error.confirmPassword ||
            (password && !passwordsMatch) || user.data.roles.length === 0;
    };

    const fetchUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            if (isValidForm()) return null;

            // Rollen-Format anpassen für das Backend
            const userData: any = { ...user.data };

            // Konvertiere die Rollen in das vom Backend erwartete Format (Set von RoleName)
            if (userData.roles && userData.roles.length > 0) {
                // Direkte Konvertierung der Rollen in Strings, wenn sie es nicht bereits sind
                userData.roles = userData.roles.map((role: any) =>
                    typeof role === 'string' ? role : role.name
                );
            }

            // Passwort hinzufügen, wenn es vorhanden ist
            if (password && passwordsMatch) {
                userData.password = password;
            }

            console.log("Sende Update mit Rollen:", userData.roles);

            const data: IUser = await userService.put(userData);

            // add item
            const dataArray: IUser[] = [...user.list];
            // search index
            let index: number = dataArray.findIndex((item: IUser) => item.id === data.id);
            // replace item
            dataArray.splice(index, 1, data);
            //update item
            dispatch(setUsers(dataArray));
            // for clean form
            dispatch(setData(new User()));

            toast.success(t('The data has been updated'));
            navigate("/users");
        } catch (error) {
            console.log(error);
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error);
            }
        }
    };

    const fetchCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            // valid fields
            if (isValidForm()) return null;

            // Rollen-Format anpassen für das Backend
            const userData: any = { ...user.data };

            // Konvertiere die Rollen in das vom Backend erwartete Format (Set von RoleName)
            if (userData.roles && userData.roles.length > 0) {
                // Direkte Konvertierung der Rollen in Strings, wenn sie es nicht bereits sind
                userData.roles = userData.roles.map((role: any) =>
                    typeof role === 'string' ? role : role.name
                );
            }

            // Add password to user data for new user
            userData.password = password;

            console.log("Sende Create mit Rollen:", userData.roles);

            const data: IUser = await userService.post(userData);
            // for clean form
            dispatch(setData(new User()));
            // add item
            const dataArray: IUser[] = [...user.list];
            dataArray.push(data);
            dispatch(setUsers(dataArray));

            toast.success('The data has been saved');
            navigate("/users");
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error);
            }
        }
    };

    const [activeTab, setActiveTab] = useState('details');

    const inputCSS = "w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary";
    const labelCSS = "mb-3 block text-sm font-medium text-black dark:text-white";
    const inputError = " border-red-400";
    const btnCSS = "flex items-center justify-center rounded border border-primary p-1 text-primary hover:bg-opacity-90";

    return (
        <div className="mx-auto max-w-270">
            <Breadcrumb pageName={t(user.data.id ? "Edit User" : "Add User")}/>

            {/* Tab Buttons */}
            <div className="mb-4 border-b border-stroke dark:border-strokedark">
                <div className="flex flex-wrap gap-4">
                    <button
                        onClick={() => setActiveTab('details')}
                        className={`inline-block p-4 ${
                            activeTab === 'details'
                                ? 'border-b-2 border-primary text-primary'
                                : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                        }`}
                    >
                        {t("User Details")}
                    </button>
                    {user.data.id && (
                        <button
                            onClick={() => setActiveTab('notes')}
                            className={`inline-block p-4 ${
                                activeTab === 'notes'
                                    ? 'border-b-2 border-primary text-primary'
                                    : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        >
                            {t("Notes")}
                        </button>
                    )}
                </div>
            </div>

            {/* Tab Content */}
            {activeTab === 'details' ? (
                <div className="grid grid-cols-1">
                    <div className="col-span-1">
                        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                            <div className="grid grid-cols-5 gap-8">
                                <div className="col-span-5 xl:col-span-5">
                                    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                                        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                                            <h3 className="font-medium text-black dark:text-white">
                                                {t(user.data.id ? "Edit User" : "Add User")}
                                            </h3>
                                        </div>

                                        <div className="p-7">
                                            <form onSubmit={(e) => user.data.id ? fetchUpdate(e) : fetchCreate(e)}>
                                                <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                                                    <div className="w-full sm:w-1/2">
                                                        <label className="mb-3 block text-sm font-medium text-black dark:text-white"
                                                               htmlFor="firstName">
                                                            {t("Firstname")}
                                                        </label>
                                                        <div className="relative">
                                                            <span className="absolute left-4.5 top-4">
                                                            </span>
                                                            <input
                                                                className={errorForm.firstName ? inputCSS + inputError : inputCSS}
                                                                id="firstName"
                                                                type="text"
                                                                placeholder={t("Maria")}
                                                                value={user.data.firstName}
                                                                onChange={(e) => setFormValue(e)}
                                                            />
                                                            {errorForm.firstName &&
                                                                <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                        </div>
                                                    </div>

                                                    <div className="w-full sm:w-1/2">
                                                        <label className="mb-3 block text-sm font-medium text-black dark:text-white"
                                                               htmlFor="lastName">
                                                            {t("Lastname")}
                                                        </label>
                                                        <input
                                                            className={errorForm.lastName ? inputCSS + inputError : inputCSS}
                                                            id="lastName"
                                                            type="text"
                                                            placeholder={t("Musterfrau")}
                                                            value={user.data.lastName}
                                                            onChange={(e) => setFormValue(e)}
                                                        />
                                                        {errorForm.lastName &&
                                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                    </div>
                                                </div>

                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="username">
                                                        {t("Username")}
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            className={errorForm.username ? inputCSS + inputError : inputCSS}
                                                            id="username"
                                                            type="username"
                                                            placeholder={t("Username")}
                                                            value={user.data.username}
                                                            onChange={(e) => setFormValue(e)}
                                                        />
                                                        {errorForm.username && <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                    </div>
                                                </div>

                                                {/* Password Fields */}
                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="password">
                                                        {t(user.data.id ? "Change Password (leave blank to keep current)" : "Password")}
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            className={errorForm.password ? inputCSS + inputError : inputCSS}
                                                            id="password"
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder={t("Password")}
                                                            value={password}
                                                            onChange={handlePasswordChange}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="absolute right-4 top-3.5 text-gray-500"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? "Hide" : "Show"}
                                                        </button>
                                                        {errorForm.password && <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                    </div>
                                                </div>

                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="confirmPassword">
                                                        {t("Confirm Password")}
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            className={errorForm.confirmPassword || !passwordsMatch ? inputCSS + inputError : inputCSS}
                                                            id="confirmPassword"
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder={t("Confirm Password")}
                                                            value={confirmPassword}
                                                            onChange={handleConfirmPasswordChange}
                                                        />
                                                        {errorForm.confirmPassword && <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                        {!passwordsMatch && password && confirmPassword && <p className="mt-1 text-m text-red-400">{t("Passwords do not match")}</p>}
                                                    </div>
                                                </div>

                                                {/* User Roles */}
                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="userRole">
                                                        {t("User Roles")}
                                                    </label>
                                                    <div className="mb-3">
                                                        <select
                                                            className={inputCSS}
                                                            id="userRole"
                                                            onChange={handleRoleChange}
                                                            defaultValue=""
                                                        >
                                                            <option value="" disabled>{t("Select a role")}</option>
                                                            {availableRoles.map((role) => (
                                                                <option key={role.id} value={role.id}>
                                                                    {role.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {/* Display selected roles */}
                                                    <div className="flex flex-wrap gap-2 mt-2">
                                                        {user.data.roles.map((role, index) => (
                                                            <div key={`role-${index}`} className="flex items-center rounded-md bg-gray-100 dark:bg-meta-4 py-1 px-2">
                                                                <span className="mr-1">
                                                                    {typeof role === 'string' ? role : role.name}
                                                                </span>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => removeRole(index)}
                                                                    className="text-sm text-red-500 ml-1"
                                                                >
                                                                    ✕
                                                                </button>
                                                            </div>
                                                        ))}
                                                        {user.data.roles.length === 0 && (
                                                            <p className="text-red-400">{t("At least one role is required")}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="projectType">
                                                        {t("Email")}
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            className={errorForm.email ? inputCSS + inputError : inputCSS}
                                                            id="email"
                                                            type="email"
                                                            placeholder={t("maria@musterfrau.de")}
                                                            value={user.data.email}
                                                            onChange={(e) => setFormValue(e)}
                                                        />
                                                        {errorForm.email &&
                                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                    </div>
                                                </div>

                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="phone">
                                                        {t("Phone")}
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            className={errorForm.phone ? inputCSS + inputError : inputCSS}
                                                            id="phone"
                                                            type="number"
                                                            placeholder={t("12345678")}
                                                            value={user.data.phone ?? ''}
                                                            onChange={(e) => setFormValue(e)}
                                                        />
                                                        {errorForm.phone &&
                                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                    </div>
                                                </div>

                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="courseStart">
                                                        {t("Course Start")}
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            className={errorForm.courseStart ? inputCSS + inputError : inputCSS}
                                                            id="courseStart"
                                                            type="date"
                                                            value={user.data.courseStart}
                                                            onChange={(e) => setFormValue(e)}
                                                        />
                                                        {errorForm.courseStart &&
                                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                                    </div>
                                                </div>

                                                {/* Status field (enabled) */}
                                                <div className="mb-5.5">
                                                    <label className={labelCSS} htmlFor="enabled">
                                                        {t("Status")}
                                                    </label>
                                                    <div className="relative flex items-center">
                                                        <input
                                                            id="enabled"
                                                            type="checkbox"
                                                            className="h-5 w-5 rounded border-gray-300"
                                                            checked={user.data.enabled}
                                                            onChange={(e) => dispatch(setData({ ...user.data, enabled: e.target.checked }))}
                                                        />
                                                        <label htmlFor="enabled" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                                                            {t("User is enabled")}
                                                        </label>
                                                    </div>
                                                </div>

                                                {/* Newsletter preferences */}
                                                <div className="mb-5.5">
                                                    <div className="mb-3 block text-sm font-medium text-black dark:text-white">
                                                        {t("Communication Preferences")}
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="flex items-center">
                                                            <input
                                                                id="receiveNewsletter"
                                                                type="checkbox"
                                                                className="h-5 w-5 rounded border-gray-300"
                                                                checked={user.data.receiveNewsletter}
                                                                onChange={(e) => dispatch(setData({ ...user.data, receiveNewsletter: e.target.checked }))}
                                                            />
                                                            <label htmlFor="receiveNewsletter" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                                                                {t("Receive Newsletter")}
                                                            </label>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <input
                                                                id="receiveLTMails"
                                                                type="checkbox"
                                                                className="h-5 w-5 rounded border-gray-300"
                                                                checked={user.data.receiveLTMails}
                                                                onChange={(e) => dispatch(setData({ ...user.data, receiveLTMails: e.target.checked }))}
                                                            />
                                                            <label htmlFor="receiveLTMails" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                                                                {t("Receive LT Mails")}
                                                            </label>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <input
                                                                id="registeredPL"
                                                                type="checkbox"
                                                                className="h-5 w-5 rounded border-gray-300"
                                                                checked={user.data.registeredPL}
                                                                onChange={(e) => dispatch(setData({ ...user.data, registeredPL: e.target.checked }))}
                                                            />
                                                            <label htmlFor="registeredPL" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                                                                {t("Registered PL")}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-end gap-4.5">
                                                    <Link to={"/users"}
                                                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                                                          type="submit">
                                                        {t("Cancel")}
                                                    </Link>

                                                    <button
                                                        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                                                        type="submit">
                                                        {user.data.id ? t("Save") : t("Create")}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                user.data.id && (
                    <div className="grid grid-cols-1">
                        <div className="col-span-1">
                            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                                <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                                    <h3 className="font-medium text-black dark:text-white">
                                        {t("User Notes")}
                                    </h3>
                                </div>
                                <div className="p-7">
                                    <UserNotes userId={user.data.id} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
}