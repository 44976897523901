import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import Breadcrumb from "../Breadcrumbs/Breadcrumb";
import {FormState, setFormData, setForms} from "../../features/slices/formSlice";
import {FormService} from "../../services/form.service";
import {Form, IForm} from "../../interfaces/Form";

export const FormForm = () => {

    let navigate = useNavigate();

    const { t } = useTranslation(['home']);

    const { form } = useSelector((state: { form: FormState }) => state);

    const [errorForm, setErrorForm] = useState({
        id: false,
        title: false,
        description: false,
    })

    const dispatch = useDispatch();

    const formService = new FormService();

    const setFormValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setFormData({ ...form.data, [event.target.id]: event.target.value }))
    }

    const isValidForm = () => {

        const error = { id: false, title: false, description: false, city: false, zip: false, state: false, country: false }

        if (!form.data.id) error.id = true
        if (!form.data.title) error.title = true
        if (!form.data.description) error.description = true

        setErrorForm(error)

        return error.id || error.title || error.description;
    }

    const fetchUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            const data: IForm = await formService.put(form.data)
            const dataArray: IForm[] = [...form.list]
            let index: number = dataArray.findIndex((item: IForm) => item.id === data.id)
            dataArray.splice(index, 1, data);
            dispatch(setForms(dataArray))

            toast.success(t('The data has been updated'))
            navigate("/Forms")
        } catch (error) {
            console.log(error)
        }
    }

    const fetchCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            // valid fields 
            if (isValidForm()) return null;

            const data: IForm = await formService.post(form.data)
            // for clean form
            dispatch(setFormData(new Form()))
            // add item
            const dataArray: IForm[] = [...form.list]
            dataArray.push(data)
            dispatch(setForms(dataArray))

            toast.success('The data has been saved')
            navigate("/Forms")
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error)
            }
        }
    }

    const inputCSS = "w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
    const inputError = "border-red-400"

    return (
        <div className="mx-auto max-w-270">
            <Breadcrumb pageName={t("Add Project")}/>

            <div className="grid grid-cols-5 gap-8">
                <div className="col-span-5 xl:col-span-5">
                    <div
                        className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                                {t("Add Project")}
                            </h3>
                        </div>

                        <div className="p-7">
                            <form onSubmit={(e) => form.data.id ? fetchUpdate(e) : fetchCreate(e)}>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800"
                                           htmlFor="title">{t("Description")}</label>
                                    <div className="relative">
                                        <input
                                            id="title"
                                            type="text"
                                            placeholder={t("Description")}
                                            value={form.data.title}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.title ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.title &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800" htmlFor="description">{t("Address")}</label>
                                    <div className="relative">
                                        <input
                                            id="description"
                                            type="text"
                                            placeholder={t("Address")}
                                            value={form.data.description}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.description ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.description &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="flex justify-end gap-4.5">
                                    <Link to={"/locations"}
                                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                                          type="submit">
                                        {t("Cancel")}
                                    </Link>

                                    <button
                                        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                                        type="submit">
                                        {form.data.id ? t("Save") : t("Create")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
