import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {IEvent} from "../../interfaces/Event";
import { Link } from "react-router-dom";
import {setEvents} from "../../features/slices/eventSlice";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {BookingService} from "../../services/booking.service";
import {FaEye} from "react-icons/fa";
import {RootState} from "../../app/store";


const TableBookings = () => {
    const { t } = useTranslation(['home']);
    const { plEvent } = useSelector((state: RootState) => state);
    const bookingService = new BookingService();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData() // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        try {
            const res: IEvent[] = await bookingService.getEventsWithBookings();
            dispatch(setEvents(res));
        } catch (error) {
            console.log('Error failed to load ==>', error);
            toast.error(t('Failed to load events'));
        }
    }

    return (
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto mb-6">
                <table className="w-full table-auto">
                    <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Event Name")}
                        </th>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Start Date")}
                        </th>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("End Date")}
                        </th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">
                            {t("Actions")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {plEvent.list.map((item: IEvent) => (
                        <tr key={item.id}>
                            <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                <Link to={`/events/${item.id}/bookings`} className="text-primary dark:text-meta-3">
                                    {item.eventName}
                                </Link>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <p className="text-black dark:text-white">
                                    {new Intl.DateTimeFormat('de-DE', {dateStyle: 'medium'}).format(new Date(item.startDate))}
                                </p>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <p className="text-black dark:text-white">
                                    {new Intl.DateTimeFormat('de-DE', {dateStyle: 'medium'}).format(new Date(item.endDate))}
                                </p>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <div className="flex items-center space-x-3.5">
                                    <Link to={`/events/${item.id}/bookings`} className="hover:text-meta-3">
                                        <FaEye />
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableBookings;
