export interface IProject {
    id?: number | null;
    projectNr: string;
    countryCode: string;
    language: string;
    projectType: string;
    projectStatus: string;
    startDate: string;
    endDate: string;
    isPublished: string;
    isPublic: string;
    courseType: string;
    eventType: string;
    eventName: string;
    leader: string;
    createdAt: string;
}

export class Project implements IProject {
    public id?: number | null;
    public projectNr: string;
    public countryCode: string;
    public language: string;
    public projectType: string;
    public projectStatus: string;
    public startDate: string;
    public endDate: string;
    public isPublished: string;
    public isPublic: string;
    public courseType: string;
    public eventType: string;
    public eventName: string;
    public leader: string;
    public createdAt: string;

    constructor() {
        this.id = null;
        this.projectNr = "";
        this.countryCode = "";
        this.language = "";
        this.projectType = "";
        this.projectStatus = "";
        this.startDate = "";
        this.endDate = "";
        this.isPublished = "";
        this.isPublic = "";
        this.courseType = "";
        this.eventType = "";
        this.eventName = "";
        this.leader = "";
        this.createdAt = "";
    }
}
