import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEvent, Event } from '../../interfaces/Event';
import {Booking} from "../../interfaces/Booking";

interface BookingState {
	bookings: Booking[];
	currentBooking: Booking | null;
}

const initialState: BookingState = {
	bookings: [],
	currentBooking: null
};

const bookingSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {
		setBookings: (state, action: PayloadAction<Booking[]>) => {
			state.bookings = action.payload;
		},
		setCurrentBooking: (state, action: PayloadAction<Booking>) => {
			state.currentBooking = action.payload;
		}
	}
});

export const { setBookings, setCurrentBooking } = bookingSlice.actions;
export default bookingSlice.reducer;