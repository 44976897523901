import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import {FormService} from "../services/form.service";
import {IForm} from "../interfaces/Form";

const ViewForm = () => {
    const { t } = useTranslation(['home'])
    const formService = new FormService();
    const { id } = useParams()

    const [form, setForm] = useState<IForm>({
        id: null,
        title: "",
        description: "",
        formFields: []
    })

    useEffect(() => {
        loadForm()
    }, [])

    const loadForm = async () => {
        try {
            if (id) {
                const result = await formService.getById(parseInt(id));
                setForm(result)
            }
        } catch (error) {
            console.error('Error loading user:', error);
            toast.error(t('Failed to load user'));
        }
    }

    return (
        <section>
            <p>
                <b>{t("Form Id")}:</b> {form.id}
            </p>
            <p>
                <b>{t("Form Name")}:</b> {form.title}
            </p>
            <p>
                <b>{t("Description")}:</b> {form.description}
            </p>

            <Link to={"/forms"} className="btn mt-8">
                {t("Cancel")}
            </Link>
        </section>
    )
}

export default ViewForm
