import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Form, IForm} from "../../interfaces/Form";

export interface FormState {
	data: IForm;
	list: IForm[]
}

const initialState: FormState = {
	data: new Form(),
	list: []
} 

export const formSlice = createSlice({
	name: 'form',
	initialState,
	reducers: { 
		setFormData: (state, action: PayloadAction<IForm>) => {
			state.data = action.payload
		},
		setForms: (state, action: PayloadAction<IForm[]>) => {
			state.list = action.payload
		},
	}
})

export const { setFormData, setForms } = formSlice.actions
export default formSlice.reducer
