import { api } from '../configs/axios';

export interface LoginRequest {
    username: string;
    password: string;
}

export interface AuthResponse {
    token: string;
    type: string;
    id: number;
    username: string;
    email: string;
    roles: string[];
}

export class AuthService {
    private static TOKEN_KEY = 'auth-token';
    private static USER_KEY = 'auth-user';
    private static REMEMBER_KEY = 'remember-me';

    async login(username: string, password: string, remember: boolean = false): Promise<AuthResponse> {
        try {
            const response = await api.post<AuthResponse>('/auth/login', {
                username,
                password,
            });

            if (response.data.token) {
                if (remember) {
                    localStorage.setItem(AuthService.TOKEN_KEY, response.data.token);
                    localStorage.setItem(AuthService.USER_KEY, JSON.stringify(response.data));
                    localStorage.setItem(AuthService.REMEMBER_KEY, 'true');
                } else {
                    sessionStorage.setItem(AuthService.TOKEN_KEY, response.data.token);
                    sessionStorage.setItem(AuthService.USER_KEY, JSON.stringify(response.data));
                }
            }

            return response.data;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    }

    logout(): void {
        localStorage.removeItem(AuthService.TOKEN_KEY);
        localStorage.removeItem(AuthService.USER_KEY);
        localStorage.removeItem(AuthService.REMEMBER_KEY);
        sessionStorage.removeItem(AuthService.TOKEN_KEY);
        sessionStorage.removeItem(AuthService.USER_KEY);
    }

    getCurrentUser(): AuthResponse | null {
        const userStr = localStorage.getItem(AuthService.USER_KEY) ||
            sessionStorage.getItem(AuthService.USER_KEY);
        if (userStr) return JSON.parse(userStr);
        return null;
    }

    getToken(): string | null {
        return localStorage.getItem(AuthService.TOKEN_KEY) ||
            sessionStorage.getItem(AuthService.TOKEN_KEY);
    }

    isAuthenticated(): boolean {
        return this.getToken() !== null;
    }
}

export const authService = new AuthService();
