import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { api } from '../../configs/axios';
import toast from 'react-hot-toast';
import Logo from '../../images/logo/logo-dark.svg';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');

    useEffect(() => {
        validateToken();
    }, [token]);


    const validateToken = async () => {
        if (!token) {
            toast.error('Invalid password reset link');
            navigate('/signin');
            return;
        }

        try {
            console.log('Validating token:', token); // Debug-Log
            const response = await api.get(`/auth/validate-reset-token`, {
                params: { token },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            console.log('Token validation response:', response.data); // Debug-Log

            if (response.data.message === 'Token is valid') {
                setValidToken(true);
            } else {
                toast.error('Password reset link is invalid or has expired');
                navigate('/signin');
            }
        } catch (error: any) {
            console.error('Token validation error:', error); // Debug-Log
            toast.error(error.response?.data?.message || 'Password reset link is invalid or has expired');
            navigate('/signin');
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        if (password.length < 6) {
            toast.error('Password must be at least 6 characters long');
            return;
        }

        setLoading(true);

        try {
            const response = await api.post('/auth/reset-password', {
                token,
                password
            });

            console.log('Reset response:', response.data); // Debug-Log

            toast.success('Password has been reset successfully');
            navigate('/signin');
        } catch (error: any) {
            console.error('Reset error:', error.response || error); // Erweitertes Error-Logging
            toast.error(
                error.response?.data?.message ||
                'Failed to reset password. Please try again.'
            );
        } finally {
            setLoading(false);
        }
    };

    if (!validToken) {
        return <div>Validating reset link...</div>;
    }

    return (
        <div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-boxdark-2 dark:text-bodydark">
            <div className="w-full max-w-md rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="flex flex-col items-center">
                    {/* Logo Section */}
                    <div className="flex w-full items-center justify-center border-b border-stroke py-4 dark:border-strokedark">
                        <img className="w-150" src={Logo} alt="Logo" />
                    </div>

                    {/* Reset Password Form Section */}
                    <div className="w-full p-4 sm:p-12.5">
                        <h2 className="mb-9 text-center text-2xl font-bold text-black dark:text-white">
                            Reset Password
                        </h2>

                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="mb-2.5 block font-medium text-black dark:text-white">
                                    New Password
                                </label>
                                <div className="relative">
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter new password"
                                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    />
                                </div>
                            </div>

                            <div className="mb-6">
                                <label className="mb-2.5 block font-medium text-black dark:text-white">
                                    Confirm Password
                                </label>
                                <div className="relative">
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm new password"
                                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    />
                                </div>
                            </div>

                            <div className="mb-5">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className={`w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 ${
                                        loading ? 'opacity-50' : ''
                                    }`}
                                >
                                    {loading ? 'Resetting Password...' : 'Reset Password'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;