import {useTranslation} from "react-i18next";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";
import TableForms from "../components/Tables/TableForms";

const FormsOverview = () => {
    const {t} = useTranslation(['home']);
    return (
        <>
            <Breadcrumb pageName={t("Forms")} />

            <div className="flex flex-col gap-10">
                <TableForms />
            </div>
        </>
    );
};

export default FormsOverview;
