import {api} from '../configs/axios';
import { IUserNote } from '../interfaces/UserNote';

export class UserNoteService {
  async getAll(userId: number): Promise<IUserNote[]> {
    const response = await api.get(`/users/${userId}/notes`);
    return response.data;
  }

  async create(userId: number, content: string): Promise<IUserNote> {
    // Content als JSON-Objekt senden
    const response = await api.post(`/users/${userId}/notes`, { content }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }

  async update(userId: number, noteId: number, content: string): Promise<IUserNote> {
    const response = await api.put(`/users/${userId}/notes/${noteId}`, { content }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }

  async delete(userId: number, noteId: number): Promise<void> {
    await api.delete(`/users/${userId}/notes/${noteId}`);
  }
}