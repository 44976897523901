import { useState } from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import Logo from '../../images/logo/logo.svg';
import { api } from '../../configs/axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await api.post('/auth/forgot-password', { email });
      setSent(true);
      toast.success('Password reset instructions have been sent to your email');
    } catch (error) {
      toast.error('Failed to send reset instructions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (sent) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-boxdark-2">
        <div className="w-full max-w-md rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:p-12.5">
          <div className="flex flex-col items-center">
            <img className="mb-8 w-150" src={Logo} alt="Logo" />
            <h2 className="mb-4 text-2xl font-bold text-black dark:text-white">Check Your Email</h2>
            <p className="mb-6 text-center text-black dark:text-white">
              We have sent password reset instructions to your email.
            </p>
            <Link
              to="/signin"
              className="text-sm text-primary hover:underline dark:text-white"
            >
              Back to Sign In
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-boxdark-2">
      <div className="w-full max-w-md rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-col items-center">
          <div className="flex w-full items-center justify-center border-b border-stroke py-4 dark:border-strokedark">
            <img className="w-150" src={Logo} alt="Logo" />
          </div>

          <div className="w-full p-4 sm:p-12.5">
            <h2 className="mb-9 text-center text-2xl font-bold text-black dark:text-white">
              Forgot Password
            </h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  Email
                </label>
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    required
                  />
                </div>
              </div>

              <div className="mb-5">
                <button
                  type="submit"
                  disabled={loading}
                  className={`w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 ${
                    loading ? 'opacity-50' : ''
                  }`}
                >
                  {loading ? 'Sending...' : 'Send Reset Instructions'}
                </button>
              </div>

              <div className="text-center">
                <Link
                  to="/signin"
                  className="text-sm text-primary hover:underline dark:text-white"
                >
                  Back to Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;