import { configureStore} from '@reduxjs/toolkit'
import userReducer from '../features/slices/userSlice'
import eventReducer from '../features/slices/eventSlice'
import projectReducer from '../features/slices/projectSlice'
import locationReducer from '../features/slices/locationSlice'
import bookingReducer from '../features/slices/bookingSlice'
import categoryReducer from '../features/slices/categorySlice'
import formReducer from '../features/slices/formSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    plEvent: eventReducer,
    plProject: projectReducer,
    location: locationReducer,
    project: projectReducer,
    booking: bookingReducer,
    category: categoryReducer,
    form: formReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(), 
})

export type RootState = ReturnType<typeof store.getState>

export default store
