import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import TableCategories from "../components/Tables/TableCategories";

const Categories = () => {
  const { t } = useTranslation(['home']);
  return (
    <>
      <Breadcrumb pageName={t("Categories")} />

      <div className="flex flex-col gap-10">
        <TableCategories />
      </div>
    </>
  );
};

export default Categories;
