export interface IUser {
    id?: number | null;
    username: string;
    firstName: string;
    lastName: string;
    phone?: number | null;
    email: string;
    courseStart: string;
    receiveNewsletter: boolean;
    receiveLTMails: boolean;
    enabled: boolean;
    registeredPL: boolean;
    roles: { id: number; name: string }[];
    createdAt: string;
}

export class User implements IUser {
    public id: null;
    public username: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: null;
    public courseStart: string;
    public receiveNewsletter: boolean;
    public receiveLTMails: boolean;
    public enabled: boolean;
    public registeredPL: boolean;
    public roles: {id: number; name: string}[];
    public createdAt: string;

    constructor() {
        this.id = null;
        this.username = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = null;
        this.courseStart = "";
        this.receiveNewsletter = false;
        this.receiveLTMails = false;
        this.enabled = false;
        this.registeredPL = false;
        this.roles = [];
        this.createdAt = "";
    }
}

export enum roles {
    ADMINISTRATOR = 'ADMINISTRATOR',
    EDITOR = 'EDITOR',
    SUBSCRIBER = 'SUBSCRIBER',
    LEADER = 'LEADER',
    COLEADER = 'COLEADER',
    PARTICIPANT = 'PARTICIPANT'
}